import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import Logo from 'assets/images/favicon.png';
import facebookIcon from 'assets/icons/facebook.svg';
import linkedinIcon from 'assets/icons/linkedin.svg';
import useLocation from 'hooks/useLocation';

const Wrapper = styled.footer`
  background: ${({ theme }) => theme.colors.darkPurple100};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.4rem;
  white-space: nowrap;
  ${({ theme }) => theme.mq.s} {
    flex-direction: row;
  }
`;

const FooterItem = styled.div`
  text-align: center;
  margin-bottom: 15px;
  &:nth-child(2) {
    order: 3;
  }
  ${({ theme }) => theme.mq.s} {
    flex: 1;
    text-align: left;
    margin-bottom: 0;
    &:nth-child(2) {
      text-align: center;
      order: 0;
    }
    &:nth-child(3) {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: auto;
    height: 65px;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const Flex = styled.div`
  display: flex;
  margin: 0 0 10px;
  justify-content: center;
  ${({ theme }) => theme.mq.s} {
    margin: 0 15px;
  }
`;

const SocialLink = styled.a`
  display: block;
  margin: 5px;
  img {
    display: block;
    width: 20px;
    height: 20px;
  }
`;

const Footer = () => {
  const location = useLocation();
  let translationContent = 'Wszelkie prawa zastrzeżone.';
  let translationPolicyPrivacy = 'Polityka prywatności';

  if (location.includes('/en')) {
    translationContent = 'All Rights Reserved.';
    translationPolicyPrivacy = 'Policy Privacy';
  }
  if (location.includes('/de')) {
    translationContent = 'Alle Rechte vorbehalten.';
    translationPolicyPrivacy = 'Datenschutz-Bestimmungen';
  }

  return (
    <Wrapper>
      <Content>
        <InnerWrapper>
          <FooterItem>
            <LogoWrapper>
              <img src={Logo} alt="logo" />
            </LogoWrapper>
          </FooterItem>
          <FooterItem>
            <span>&copy; {translationContent}</span>
          </FooterItem>
          <FooterItem>
            <Flex>
              {/* <SocialLink href="#" target="_blank">
                <img src={facebookIcon} alt="Facebook" />
              </SocialLink> */}
              <SocialLink
                href="https://www.linkedin.com/company/aseti/about/"
                target="_blank"
              >
                <img src={linkedinIcon} alt="LinkedIn" />
              </SocialLink>
            </Flex>
            {location === '/polityka-prywatnosci' ? (
              <StyledLink as="a" href="">
                {translationPolicyPrivacy}
              </StyledLink>
            ) : (
              <StyledLink to="/polityka-prywatnosci">
                {translationPolicyPrivacy}
              </StyledLink>
            )}
          </FooterItem>
        </InnerWrapper>
      </Content>
    </Wrapper>
  );
};

export default Footer;
