const breakpoints = {
  xs: 440,
  s: 576,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1440,
  xxxl: 1700,
};

export const theme = {
  fonts: {
    mainFont: `'Poppins', sans-serif`,
  },
  colors: {
    white: '#ffffff',
    dark: '#23193d',
    red: '#E30613',
    red100: '#ed395d',
    green: '#57c766',
    purple: '#783dab',
    darkPurple: '#1D1435',
    darkPurple100: '#23193e',
    blue: '#00a6e8',
    gray: '#7D7D7D',
    gray100: '#707070',
    gray200: '#EEEEEE',
    gray300: '#cccccc',
  },
  gradients: {
    purpleBlue:
      'linear-gradient(to right, #8037bd, #6a52cd, #5267d9, #3778e0, #1f88e3)',
    purple:
      'linear-gradient(to right, #7232aa, #5d2c91, #4a2577, #391e5f, #291747)',
  },
  light: 300,
  regular: 400,
  medium: 500,
  bold: 900,
  fontSize: {
    xxs: '0.9rem',
    xs: '1.1rem',
    s: '1.4rem',
    m: '1.6rem',
    md: '1.9rem',
    lg: '2.2rem',
    lgm: '2.6rem',
    xl: '3rem',
    xlg: '3.6rem',
    xxl: '6rem',
    xxxl: '7.2rem',
  },
  navSize: {
    desktop: '120px',
    mobile: '80px',
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
    return acc;
  }, {}),
};
