import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoIcon from 'assets/icons/aseti_logo.svg';
import scrollToSection from 'utils/scrollToSection';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import useLocation from 'hooks/useLocation';

const Wrapper = styled.div`
  display: block;
  width: 130px;
  height: ${({ theme }) => theme.navSize.mobile};
  cursor: pointer;
  padding: 20px 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 10px 0;
    width: 170px;
    height: ${({ theme }) => theme.navSize.desktop};
  }
`;

const InnerWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Logo = ({ close }) => {
  const location = useLocation();

  return (
    <>
      {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
        <Wrapper onClick={() => close('#home')}>
          <InnerWrapper as={AnchorLink} to="/#home" stripHash>
            <Image src={logoIcon} alt="logo" />
          </InnerWrapper>
        </Wrapper>
      ) : (
        <Wrapper
          onClick={() => {
            scrollToSection('#home');
            close();
          }}
        >
          <Image src={logoIcon} alt="logo" />
        </Wrapper>
      )}
    </>
  );
};

Logo.propTypes = {
  close: PropTypes.func,
};

Logo.defaultProps = {
  close: null,
};

export default Logo;
