import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import Logo from 'components/shared/Logo';
import menuIcon from 'assets/icons/menu.svg';
import logoIcon from 'assets/icons/sap_logo.svg';
import Links from 'components/Navigation/Links';
import Sidenav from 'components/Navigation/Sidenav';
import useMedia from 'hooks/useMedia';
import useLocation from 'hooks/useLocation';
import scrollToSection from 'utils/scrollToSection';

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.navSize.mobile};
  z-index: 100;
  transform: ${({ navHidden, theme }) =>
    navHidden ? `translate3d(0, -${theme.navSize.mobile}, 0)` : 'none'};
  transition: all ease-in-out 250ms;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  @media (min-width: 1840px) {
    height: ${({ theme }) => theme.navSize.desktop};
    transform: ${({ navHidden, theme }) =>
      navHidden ? `translate3d(0, -${theme.navSize.desktop}, 0)` : 'none'};
    ${({ transparent }) =>
      transparent &&
      css`
        box-shadow: none;
        background-color: transparent;
        & #language_de,
        & #language_pl,
        & #language_en {
          color: ${({ theme }) => theme.colors.white};
        }

        & #language_switcher {
          & > div {
            border-color: #fff !important;
            color: #fff;
          }
        }
      `}
  }

  & #language_switcher {
    display: none;
  }

  ${({ theme }) => theme.mq.xl} {
    & #language_switcher {
      display: block;
    }
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: none;
  width: 100%;
  padding: 0 10px;
  ${({ theme }) => theme.mq.s} {
    display: flex;
    justify-content: space-evenly;
  }
`;

const LogoSAP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: 20px;
  height: 40px;

  & > div {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;

const MenuButton = styled.button`
  background: transparent;
  cursor: pointer;
  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;

const StyledContent = styled(Content)`
  max-width: 1900px;
`;

const Navigation = () => {
  const [isTransparent, setIsTransparent] = useState(true);
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isSidenavVisible, setIsSidenavVisible] = useState(false);
  const [scrollSection, setScrollSection] = useState(null);
  const location = useLocation();

  useEffect(() => {
    scrollToSection(scrollSection);
    setIsNavHidden(false);
  }, [location]);

  let prevScroll = 0;

  const handleScroll = e => {
    const window = e.currentTarget;
    const isHomepage = window.location.pathname === '/';

    if (window.scrollY < 100 && isHomepage) {
      setIsTransparent(true);
    } else if (prevScroll > window.scrollY) {
      setIsNavHidden(false);
    } else if (window.scrollY >= 100) {
      setIsNavHidden(true);
      setIsTransparent(false);
    }
    prevScroll = window.scrollY;
  };

  useEffect(() => {
    const isHomepage = window.location.pathname === '/';

    setIsTransparent(window.scrollY < 100 && isHomepage);
    window.addEventListener('scroll', e => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e));
    };
  }, []);

  const hideNav = () => {
    if (window.scrollY >= 100) setIsNavHidden(true);
  };

  const closeSidenav = () => {
    setIsSidenavVisible(false);
    setTimeout(hideNav, 800);
    document.body.style.overflow = 'auto';
  };

  const openSidenav = () => {
    setIsSidenavVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const handleClose = section => {
    setTimeout(hideNav, 800);
    setScrollSection(section);
    setScrollSection(null);
  };

  const matchesXL = useMedia('(min-width: 1280px)');

  return (
    <>
      {!matchesXL && (
        <Sidenav
          isVisible={isSidenavVisible}
          close={section => {
            closeSidenav();
            handleClose(section);
          }}
        />
      )}
      <Wrapper transparent={isTransparent} navHidden={isNavHidden}>
        <StyledContent>
          <InnerWrapper>
            <Logo close={handleClose} />
            <LogoSAP>
              <Icon src={logoIcon} alt="logo" />
            </LogoSAP>
            <LinksWrapper>
              <Links
                transparentLink={isTransparent}
                close={section => handleClose(section)}
              />
            </LinksWrapper>
            {!matchesXL && (
              <MenuButton aria-label="menu" onClick={openSidenav}>
                <Icon size={40} src={menuIcon} />
              </MenuButton>
            )}
          </InnerWrapper>
        </StyledContent>
      </Wrapper>
    </>
  );
};

export default Navigation;
