import styled from 'styled-components';

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1580px;
  padding: 5px 12px;
  font-weight: ${({ theme }) => theme.medium};
  font-size: 1.6rem;
  ${({ theme }) => theme.mq.md} {
    padding: 5px 20px;
    font-size: 2rem;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 5px 30px;
    font-size: 2.4rem;
  }
`;

export default Content;
