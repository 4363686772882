import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import scrollToSection from 'utils/scrollToSection';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Icon from 'components/shared/Icon';
import emailSvg from 'assets/icons/email.svg';
import useLocation from 'hooks/useLocation';

const getBlogLink = location => {
  const first3Letters = location.slice(0, 3);

  if (first3Letters.includes('/en')) return '/en/blog';
  if (first3Letters.includes('/de')) return '/de/blog';
  else return '/blog';
};

const LinkItem = styled.li`
  display: none;
  color: ${({ theme }) => theme.colors.darkPurple};
  font-weight: ${({ theme }) => theme.regular};
  font-size: 2.2rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 20px 0;
  /* @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  } */
  ${({ theme }) => theme.mq.xl} {
    display: block;
    margin: 0 10px;
    font-size: clamp(1.6rem, 1.1vw, 2.2rem);
  }
  ${({ theme }) => theme.mq.xxxl} {
    margin: 0 25px;
    font-size: 1.85rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  @media (min-width: 1900px) {
    font-size: 2.2rem;
  }
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    margin-left: 20px;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-left: 40px;
  }
  ${({ isSidenav }) =>
    isSidenav &&
    css`
      & > ${LinkItem} {
        display: block !important;
      }
    `}
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const ContactLinkItem = styled(LinkItem)`
  align-self: flex-end;
  display: block !important;
  ${({ theme }) => theme.mq.xl} {
    margin: 0 10px 0 auto !important;
  }
`;

const ContactLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.medium};
  padding: 15px 30px;
  border-radius: 50px;
  border: 1px solid #111;
  transition: 0.3s;
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.purple};
      border-color: ${({ theme }) => theme.colors.purple};
      color: ${({ theme }) => theme.colors.white};
    }
  }
  & > span {
    margin-left: 15px;
  }
  @media (min-width: 1840px) {
    padding: 25px 40px;
    ${({ transparent }) =>
      transparent &&
      css`
        background: transparent;
        border-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};
      `}
  }
`;

const LanguageSwitcherToggler = styled.div`
  display: none;
  height: 56px;
  width: 56px;
  border-radius: 50px;
  border: 1px solid #000;
  cursor: pointer;

  ${({ theme }) => theme.mq.xl} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const LanguageSwitcherList = styled.div`
  position: relative;
  top: 0;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  overflow: hidden;
  right: 0;
  padding: 10px 0;
  border-radius: 10px;
  transition: opacity 300ms ease-in-out, height 800ms ease-in-out;

  ${({ theme }) => theme.mq.xl} {
    width: 56px;
    flex-direction: column;
    height: 0;
    opacity: 0;
    position: absolute;
    background-color: #fff;
    box-shadow: 4px 4px 8px 2px #d4d4d4;
    top: 55px;
  }
`;
const LanguageSwitcherItem = styled(Link)`
  font-size: 18px;
  margin: 10px;
  font-weight: 400;
  text-decoration: none;
  color: #000;
  text-align: center;
  width: 100%;
`;

const LanguageSwitcherWrapper = styled.li`
  position: relative;
`;

const LanguageSwitcher = styled.div`
  ${({ theme }) => theme.mq.xl} {
    &:hover {
      & ${LanguageSwitcherList} {
        opacity: 1;
        top: 55px;
        height: auto;
      }
    }
  }
`;

const Links = ({ sidenav, transparentLink, close }) => {
  const location = useLocation();

  console.log(location);

  let translationLinkAbout = 'O nas';
  let translationLinkExpertise = 'Nasze kompetencje';
  let translationLinkTeam = 'Zespół';
  let translationLinkOffer = 'Oferta';
  let translationLinkCertificate = 'Certyfikaty';
  let translationLinkWhy = 'Dlaczego SAP';
  let translationLinkCareer = 'Kariera';

  if (location.includes('/en')) {
    translationLinkAbout = 'About us';
    translationLinkExpertise = 'Our expertise';
    translationLinkTeam = 'Team';
    translationLinkOffer = 'Our offer';
    translationLinkCertificate = 'Certificate';
    translationLinkWhy = 'Why SAP';
    translationLinkCareer = 'Career';
  }

  if (location.includes('/de')) {
    translationLinkAbout = 'Über uns';
    translationLinkExpertise = 'Unsere Kompetenzen';
    translationLinkTeam = 'Team';
    translationLinkOffer = 'Offerte';
    translationLinkCertificate = 'Zertifikate';
    translationLinkWhy = 'Warum SAP?';
    translationLinkCareer = 'Karriere';
  }

  return (
    <>
      <List isSidenav={sidenav}>
        <LinkItem onClick={() => close('#about')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink
              as={AnchorLink}
              to="/#about"
              title={translationLinkAbout}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#about')}>
              {translationLinkAbout}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#competences')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink
              as={AnchorLink}
              to="/#competences"
              title={translationLinkExpertise}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#competences')}>
              {translationLinkExpertise}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#team')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink
              as={AnchorLink}
              to="/#team"
              title={translationLinkTeam}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#team')}>
              {translationLinkTeam}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#career')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink
              as={AnchorLink}
              to="/#career"
              title={translationLinkCareer}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#career')}>
              {translationLinkCareer}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#offer')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink
              as={AnchorLink}
              to="/#offer"
              title={translationLinkOffer}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#offer')}>
              {translationLinkOffer}
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#ksef')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink as={AnchorLink} href="/ksef" title="Aseti-KSeF" />
          ) : (
            <StyledLink href="/ksef">Aseti-KSeF</StyledLink>
          )}
        </LinkItem>
        {/* <LinkItem onClick={() => close('#certificates')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink
              as={AnchorLink}
              to="/#certificates"
              title={translationLinkCertificate}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#certificates')}>
              {translationLinkCertificate}
            </StyledLink>
          )}
        </LinkItem> */}
        {/* <LinkItem onClick={() => close('#whySAP')}>
          {!['/', '/en', '/en/', '/de', '/de/'].includes(location) ? (
            <StyledLink
              as={AnchorLink}
              to="/#whySAP"
              title={translationLinkWhy}
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#whySAP')}>
              {translationLinkWhy}
            </StyledLink>
          )}
        </LinkItem> */}
        <LinkItem onClick={() => close('#blog')}>
          <StyledLink href={getBlogLink(location)}>BlogTech</StyledLink>
        </LinkItem>
        <ContactLinkItem>
          <ContactLink
            transparent={transparentLink}
            href="mailto: biuro@aseti.pl"
          >
            <Icon src={emailSvg} alt="email" />
            <span>biuro@aseti.pl</span>
          </ContactLink>
        </ContactLinkItem>
        <LanguageSwitcherWrapper>
          <LanguageSwitcher id="language_switcher">
            <LanguageSwitcherToggler id="language_switcher_toggler">
              {location.includes('/en') || location.includes('/de')
                ? location.includes('/de')
                  ? 'DE'
                  : 'EN'
                : 'PL'}
            </LanguageSwitcherToggler>
            <LanguageSwitcherList id="language_switcher_button">
              <LanguageSwitcherItem to="/">PL</LanguageSwitcherItem>
              <LanguageSwitcherItem to="/en/">EN</LanguageSwitcherItem>
              <LanguageSwitcherItem to="/de/">DE</LanguageSwitcherItem>
            </LanguageSwitcherList>
          </LanguageSwitcher>
        </LanguageSwitcherWrapper>
      </List>
    </>
  );
};

Links.propTypes = {
  transparentLink: PropTypes.bool,
  sidenav: PropTypes.bool,
  close: PropTypes.func,
};

Links.defaultProps = {
  close: () => null,
  sidenav: false,
  transparentLink: false,
};

export default Links;
